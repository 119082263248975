import { FdsButton, FdsLink, FdsNavListItem, FdsNavListItems } from '@lyse-as/formds-react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Breakpoint, Typography as T } from 'app/component-library-wave';
import { ContainerFixed } from 'app/components/container';
import { CustomerPage } from 'app/pages/page-wrapper';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { getInvoicePaymentInformation, getInvoices, getMoreInvoices } from 'app/store/actions/invoice-thunks';
import { FetchStatus } from 'app/store/root-types';
import { Spinner } from 'app/components/spinner';

import { ContainerLayoutMode } from 'app/components/container/container';
import { navigationService } from 'app/service/navigation/navigation-service';
import { iso8601ToFullMonthAndYear, iso8601ToShortDate } from 'app/utils/date-utils';
import { useAppNavigation } from 'app/utils/navigation-utils';
import { InvoiceOverview } from './invoice-overview/invoice-overview';
import { settlementIconStates, SettlementState } from './settlement-state/settlement-state';
import { useDownloadInvoicePdf } from './invoice-utils';
import styles from './invoice.module.scss';
import { InvoiceCustomerServiceLink } from './invoice-customerservice-link/invoice-customerservice-link';

export const InvoicePage: CustomerPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fetchStatus, invoices, paymentInformation } = useAppSelector((state) => state.invoice);
  const [loadedInvoicesFrom, setLoadedInvoicesFrom] = useState<number>();
  const { downloadPdf, downloadFetchStatus } = useDownloadInvoicePdf();
  const [latestInvoice, setLatestInvoice] = useState<MinesiderBackend.Invoice>();
  const { goToPath } = useAppNavigation();

  useEffect(() => {
    const today = new Date();
    const fromDate = today.setMonth(today.getMonth() - 8); // Unix time in ms
    const fromDateFormatted = new Date(fromDate).toISOString().split('T')[0]; // 'yyyy-mm-dd'
    const to = '2100-01-01';

    setLoadedInvoicesFrom(fromDate);
    dispatch(getInvoices({ from: fromDateFormatted, to }));
    dispatch(getInvoicePaymentInformation());
  }, []);

  useEffect(() => {
    if (invoices) {
      setLatestInvoice(invoices[0]);
    }
  }, [invoices]);

  function loadMoreInvoices() {
    const prevFromDate = new Date(loadedInvoicesFrom || Date.now());
    const newTo = new Date(loadedInvoicesFrom || Date.now()).toISOString().split('T')[0]; // 'yyyy-mm-dd'

    const newFrom = prevFromDate.setMonth(prevFromDate.getMonth() - 12);
    const newFromFormatted = new Date(newFrom).toISOString().split('T')[0]; // 'yyyy-mm-dd'
    setLoadedInvoicesFrom(newFrom);

    dispatch(getMoreInvoices({ from: newFromFormatted, to: newTo }));
  }

  const getLoadedInvoicesFromMessage = () => {
    const loadedFromDate = new Date(loadedInvoicesFrom || Date.now()).toISOString();
    const formattedLoadedFromDate = iso8601ToShortDate(loadedFromDate);
    return t('pages.invoice.loadedInvoicesFromDateMessage', {
      date: formattedLoadedFromDate,
    });
  };

  return (
    <ContainerFixed mode={ContainerLayoutMode.Medium} className={styles.pageContainer}>
      <T
        variant="formds-altibox-header-tertiary"
        component="h1"
        maxBreakpoint={Breakpoint.TABLET}
        bold={true}
        className={styles.pageHeading}
      >
        {t('pages.invoice.name')}
      </T>
      <T variant="formds-body" component="p" className={styles.ingress}>
        {t('pages.invoice.ingress')}
      </T>

      <T variant="formds-lead" component="h2" bold={true} className={styles.invoiceLeadHeading}>
        {t('pages.invoice.latestInvoice')}
      </T>

      <section className={styles.borderContainer}>
        {fetchStatus === FetchStatus.PENDING && !latestInvoice && <Spinner />}
        {latestInvoice && (
          <>
            <InvoiceOverview invoice={latestInvoice} paymentInformation={paymentInformation} />
            <div className={styles.detailsButtonContainer}>
              <FdsButton
                variant="primary"
                iconRight="launch"
                fullWidth={true}
                onClick={() => downloadPdf(latestInvoice.id)}
                isLoading={downloadFetchStatus === FetchStatus.PENDING}
              >
                {t('pages.invoice.downloadInvoice')}
              </FdsButton>
              <FdsButton
                fullWidth={true}
                variant="secondary"
                onClick={() => goToPath(navigationService.getPostponeUrl(latestInvoice.id!))}
              >
                {t('pages.invoice.postponeInvoice')}
              </FdsButton>
              <FdsLink
                icon="arrow_forward"
                className={styles.detailsLink}
                href={`${navigationService.PAGES.invoiceDetails.url}?invoiceId=${latestInvoice.id}`}
                preventReload={true}
              >
                {t('pages.invoice.seeInvoiceDetails')}
              </FdsLink>
            </div>
          </>
        )}
        {!latestInvoice && fetchStatus !== FetchStatus.PENDING && (
          <T variant="formds-body" component="p">
            {t('pages.invoice.noRecentInvoices')}
          </T>
        )}
      </section>

      <section>
        <T variant="formds-lead" component="h2" bold={true}>
          {t('pages.invoice.previousInvoices')}
        </T>
        <T variant="formds-body" component="p" className={styles.loadedInvoicesFromMessage} aria-live="polite">
          {getLoadedInvoicesFromMessage()}
        </T>
        <div className={styles.borderContainer}>
          {invoices.length === 0 && fetchStatus === FetchStatus.FULFILLED ? (
            <T variant="formds-body" component="p">
              {t('pages.invoice.noInvoicesInSelectedDateRange')}
            </T>
          ) : (
            <FdsNavListItems className={styles.previousInvoicesList}>
              {invoices
                .filter((invoice) => invoice.id !== latestInvoice?.id)
                .map((invoice) => {
                  const { iconName, i18nKey } = settlementIconStates({
                    settlementState: invoice.settlementState as SettlementState,
                    dueDate: invoice.dueDate,
                  });
                  return (
                    <FdsNavListItem
                      header={iso8601ToFullMonthAndYear(invoice.billDate || '')}
                      href={`${navigationService.PAGES.invoiceDetails.url}?invoiceId=${invoice.id}`}
                      description={t(i18nKey)}
                      headerIcon={iconName}
                      navText={`${invoice.taxIncludedSum} kr`}
                      navIcon="chevron_right"
                      key={invoice.id}
                      preventReload={true}
                    />
                  );
                })}
            </FdsNavListItems>
          )}
        </div>

        <div className={styles.centerContainer}>
          <FdsButton
            className={styles.loadMoreInvoices}
            onClick={loadMoreInvoices}
            isLoading={fetchStatus === FetchStatus.PENDING}
            loadingText={t('pages.invoice.loadingInvoices')}
            variant="primary"
          >
            {t('pages.invoice.loadOlderInvoices')}
          </FdsButton>
        </div>
      </section>

      {paymentInformation?.paymentMethod !== 'AVTALEGIRO' && (
        <section className={styles.avtaleGiro}>
          <T variant="formds-lead" component="h2" bold={true} className={styles.invoicingQuestionsHeader}>
            {t('pages.invoice.avtaleGiro')}
          </T>
          <T component="p" className={styles.invoicingQuestionsHeader} variant="formds-body">
            {t('pages.invoice.avtaleGiroDescription')}
          </T>
          <FdsLink href={navigationService.PAGES.invoiceAvtaleGiro.url} icon="launch" preventReload={true}>
            {t('pages.invoice.createAvtaleGiro')}
          </FdsLink>
        </section>
      )}

      <div className={styles.pageBottomMargin}>
        <InvoiceCustomerServiceLink />
      </div>
    </ContainerFixed>
  );
};
