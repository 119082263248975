import React from 'react';
import { FdsAlert } from '@lyse-as/formds-react';

import { Typography, Breakpoint } from 'app/component-library-wave';
import { i18n } from 'app/i18n/i18n';
import { formatDate } from 'app/utils/date-utils';
import { OperationalMessageDetails } from 'app/store/root-types';
import { ActiveCustomerLocationContext } from 'app/store/types/user-context-types';

import styles from './operational-message-alert.module.scss';

interface Props {
  message: OperationalMessageDetails;
  activeUserContext?: ActiveCustomerLocationContext;
}

export const OperationalMessageAlert: React.FC<Props> = (props) => {
  const { message, activeUserContext } = props;
  const titleKey = `features.operationalMessages.DYNAMIC_KEYS.${message.webTemplate}.title`;
  const descriptionKey = `features.operationalMessages.DYNAMIC_KEYS.${message.webTemplate}.description`;

  const customTitle = message.webSummary || '';
  const customDescription = message.webDescription || '';
  const title = !customTitle && i18n.exists(titleKey) ? i18n.t(titleKey) : customTitle;
  const description = !customDescription && i18n.exists(descriptionKey) ? i18n.t(descriptionKey) : customDescription;
  const isRelevantToCustomer =
    activeUserContext?.services && message.affectedProducts.length > 0
      ? activeUserContext?.services?.some((service) => message.affectedProducts.includes(service))
      : true;

  if (!isRelevantToCustomer) {
    return null;
  }

  return title && description ? (
    <FdsAlert variant="warning" className={styles.alert}>
      <h2 slot="alert-heading">{title}</h2>
      <p>{description}</p>

      <div className={styles.flexContainer}>
        <div className={styles.column}>
          <Typography
            className={styles.columnHeader}
            variant="uiText3"
            component="div"
            bold={true}
            maxBreakpoint={Breakpoint.TABLET}
          >
            {i18n.t('features.operationalMessages.created').toString()}
          </Typography>
          <Typography variant="paragraph3" component="div" maxBreakpoint={Breakpoint.TABLET}>
            {formatDate(message.dateOfOccurence)}
          </Typography>
        </div>
        <div className={styles.column}>
          <Typography
            className={styles.columnHeader}
            variant="uiText3"
            component="div"
            bold={true}
            maxBreakpoint={Breakpoint.TABLET}
          >
            {i18n.t('features.operationalMessages.affectedProducts').toString()}
          </Typography>
          <Typography variant="paragraph3" component="div" maxBreakpoint={Breakpoint.TABLET}>
            {message.affectedProducts}
          </Typography>
        </div>
      </div>
    </FdsAlert>
  ) : null;
};
